import React from 'react'
import styled from "styled-components"
import Shield from "../../images/OtherAssets/tarcza.svg"

const StyledCheckboxInput = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-right: 16px;
	height: calc(0.9 * (50vw - 38px));
	width: calc(50% - 8px);
	max-height: 130px;
	cursor: pointer;
	z-index: 0; 
	transition: opacity .3s;
	margin-top: 12px;
	@media(min-width: 414px) {
		width: 107px;
		height: 96px;
		max-height: unset;
	}
	@media(min-width: 768px) {
		margin-top: 12px;
		height: 150px;
		width: 160px;
		margin-right: 20px;
	}
	@media(min-width: 1920px) {
		width: 170px;
	}
	input {
		margin: 0;
		width: 100% !important;
		height: 100% !important;
		z-index: 20;
		cursor: pointer;
		border: 1px solid black;
		@media(min-width: 768px) {
		    margin-top: 12px;
		    margin-right: 5px;
        	width: 160px !important;
		    height: 150px !important;
		}
	}
	p {
	    padding-left: 0;
	    font-size: 16px;
	    line-height: 20px;
	    font-weight: 600;
	    color: ${({theme}) => theme.colors.formDarkGreen};
	    padding-top: 22px;
	    z-index: 10;
	    position: relative;
	    text-align: center;
	    margin-top: -25px;
		width: 100%;
	    @media(min-width: 768px) {
            font-size: 18px;
	    	line-height: 22px;
	    	margin-top: -40px;
	    }
        @media(min-width: 1920px) {
			text-align: center;
			width: 100%;
			margin-left: 0;
  		}	
    }
	.custom-checkbox {
		opacity: 0;
	}
`;

const CheckboxLabel = styled.label`
		position: relative;
		background-color: ${({ checked, theme }) => checked ? theme.colors.formTileActiveBackgroundOrange : theme.colors.formWhite};
		border-radius: 6px;
		border: ${({ checked, theme }) => checked ? "2px solid " + theme.colors.formTileActiveOrange : "1px solid " + theme.colors.formLightGreen};
		box-shadow: ${({checked}) => checked ? "0 3px 5px 0 rgba(0,0,0,0.09) !important" : ""};
		padding: ${({checked}) => checked ? "25px 10px 10px;" : "24px 10px 9px;"};
		opacity: 1;
		z-index: 4;
		width: 100%;
		height: 100%;
		top: 20px;
		cursor: pointer;
		outline: none;
		&:hover {
			border: ${({checked, theme}) =>  checked ? "2px solid" + theme.colors.formDarkOrange : "1px solid" + theme.colors.formDarkGreen};
		}
		@media(min-width: 414px) {
			width: 107px;
			height: 96px;
		}
		@media(min-width: 768px) {
      		width: 160px;
		 	height: 150px;
		}
		@media(min-width: 1024px) {
      		width: 160px;
		 	height: 150px;
		}
		@media(min-width: 1920px) {
			width: 170px;
  		}	
`;

const ShieldWrapper = styled.div`
	position: absolute;
	z-index: 10;
	cursor: pointer;
	left: calc(50% - 20px);
	top: 32px;
	background-image: url(${Shield});
	background-repeat: no-repeat;
	background-size: contain;
    width: 40px;
	height: 50px;
	@media(min-width: 340px) {
		width: 52px;
		left: calc(50% - 26px);
	}
	@media(min-width: 375px) {
		width: 56px;
	}
	@media(min-width: 414px) {
		width: 44px;
		left: calc(50% - 22px);
	}
	@media(min-width: 768px) {
		left: calc(50% - 29px);
		top: 45px;
		width: 57px;
		height: 71px;
	}
	@media(min-width: 1024px) {
		top: 40px;
	}
`

const CustomCheckboxTilt = ({children, required, checked, onClick, index}) => {
	return (
		<StyledCheckboxInput onClick={onClick} >
			<ShieldWrapper onClick={onClick} checked={checked}/>
			<CheckboxLabel htmlFor={"form" + index} checked={checked} onClick={onClick} required={required}>
				<input type="checkbox" className="custom-checkbox" id={"form" + index}  checked={checked} onClick={onClick}/>
			</CheckboxLabel>
			<div>{children}</div>
		</StyledCheckboxInput>
	)
}

export default CustomCheckboxTilt;
