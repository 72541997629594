import React, {useState} from "react"
import styled from "styled-components"
import {Header} from "../common/Header";
import {CategoryHeader} from "../common/CategoryHeader";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import {useDispatch, useSelector} from "react-redux"
import {setFormValueAction} from "../../state/form";
import {ContentWrapper} from "../common/ContentWrapper";
import CustomDatePicker from "../common/CustomDatepicker";
import {Formik} from "formik";
import {navigate} from "gatsby";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {GreenHint, TabWrapper} from "../firstStepFirstTab/FirstStepFirstTab";
import CustomCheckboxTilt from "../common/CustomCheckboxTilt";
import {setSecondStepFilled} from "../../state/setPagesAreFilled"

const CheckboxesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: -15px;
	margin-bottom: 50px;
	flex-wrap: wrap;
		div {
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 414px) {
		justify-content: flex-start;
		div {
			&:nth-child(2n) {
				margin-right: 16px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	@media(min-width: 768px) {
		div {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: unset;
			}
		}
	}
	@media(min-width: 1024px) {
		margin-bottom: 80px;
	}
`;

const SecondStepFirstTab = () => {
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)

	const [isOCChecked, toggleOC] = useState(false);
	const [isACChecked, toggleAC] = useState(false);
	const [isNNWChecked, toggleNNW] = useState(false);
	const insuranceBeginningDate = new Date();

	const handleOCInsurance = () => {
		toggleOC(!isOCChecked);
		dispatch(setFormValueAction("isOCInsurance", !isOCChecked));
		dispatch(setFormValueAction("isOCInsuranceLabel", (!isOCChecked ? "tak" : "nie")));
	}
	const handleACInsurance = () => {
		toggleAC(!isACChecked);
		dispatch(setFormValueAction("isACInsurance", !isACChecked));
		dispatch(setFormValueAction("isACInsuranceLabel", (!isACChecked ? "tak" : "nie")));
	}
	const handleNNWInsurance = () => {
		toggleNNW(!isNNWChecked);
		dispatch(setFormValueAction("isNNWInsurance", !isNNWChecked));
		dispatch(setFormValueAction("isNNWInsuranceLabel", (!isNNWChecked ? "tak" : "nie")));
	}

	const calendarDateFormat = "dd/MM/yyyy";

	return (
		<TabWrapper>
			<Header className="tablet-hidden">Ubezpieczenia</Header>
			<GreenHint className="tablet-hidden">wypełnij formularz i porównaj ceny</GreenHint>
			<ContentWrapper>
				<Formik
					initialValues={{
						isOCInsurance: formReducer.isOCInsurance,
						isACInsurance: formReducer.isACInsurance,
						isNNWInsurance: formReducer.isNNWInsurance,
						insuranceBeginningDate: formReducer.insuranceBeginningDate,
					}}
			        validate={values => {
				        const errors = {};
				        if (!values.insuranceBeginningDate) {
					        errors.insuranceBeginningDate = 'pole wymagane'
				        }
				        return errors;
			        }}
			        onSubmit={(values, {setSubmitting}) => {
				        setSubmitting(false);
				        dispatch(setFormValueAction("insuranceBeginningDate", values.insuranceBeginningDate))
				        navigate('/step-3')
				        dispatch(setSecondStepFilled(true))
			        }}>
					{({values, errors, isSubmitting, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
						<form onSubmit={handleSubmit}>
							<CategoryHeader className="with-checkbox">Jakim ubezpieczeniem jesteś zainteresowany?</CategoryHeader>
							<CheckboxesWrapper>
								<CustomCheckboxTilt
									index={1}
									checked={formReducer.isOCInsurance}
									onClick={handleOCInsurance}>
									<p>OC</p>
								</CustomCheckboxTilt>
								<CustomCheckboxTilt
									index={2}
									checked={formReducer.isACInsurance}
									onClick={handleACInsurance}>
									<p>AC</p>
								</CustomCheckboxTilt>
								<CustomCheckboxTilt
									index={3}
									checked={formReducer.isNNWInsurance}
									onClick={handleNNWInsurance}>
									<p>NNW</p>
								</CustomCheckboxTilt>
							</CheckboxesWrapper>
							<CustomDatePicker label={"Data rozpoczęcia ubezpieczenia:"}
							                  type="date"
							                  placeholderText={"wybierz datę"}
							                  name="insuranceBeginningDate"
							                  required
							                  selected={values.insuranceBeginningDate}
							                  onChange={(val) => setFieldValue("insuranceBeginningDate", val)}
							                  minDate={insuranceBeginningDate}
							                  locale={'pl'}
											  dateFormat={calendarDateFormat}
								              pickerClassName="datepicker"/>
							<NavigationButtonsWrapper className="whole-margin">
								<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"} to="/step-1-3"/>
								<NextStepButton type="submit"
								                isDisabled={isSubmitting}
								                isVisible={true} textBtn={"DALEJ"} />
							</NavigationButtonsWrapper>
						</form>)}
				</Formik>
			</ContentWrapper>
		</TabWrapper>
	)
};

export default SecondStepFirstTab;
