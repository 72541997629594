import React from "react";
import SecondStepFirstTab from "../components/secondStepFirstTab/SecondStepFirstTab";

const Step2 = () => {
	return (
		<>
			<SecondStepFirstTab/>
		</>
	)
};

export default Step2
